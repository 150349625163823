import axios from 'axios';

const getDomain = () => {
    const properties = require('../properties/tracking-app.json');
    return properties.apiDomain;
};

export const get = async (path) => {
    return new Promise((resolve, reject) => {
        axios
            .get(getDomain() + path)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}

export const postJSON = async (path, json) => {
    return new Promise((resolve, reject) => {
        axios
            .post(getDomain() + path, json, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                        }
            })
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

export const postForm = async (path, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(getDomain() + path, data)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};
