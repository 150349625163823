import { Tab } from 'bootstrap';
import React, { useContext, useEffect } from 'react';
import * as API from '../../api/API';
import { Tabs } from 'react-bootstrap';
import Tracking from '../pages/tracking/Tracking';
import BatchTracking from '../pages/tracking/BatchTracking';
import RawTracking from '../pages/tracking/RawTracking';
import Alert from '../alert/Alert';
import { AlertContext } from '../providers/AlertProvider';

export default function Dashboard() {
    const alert = useContext(AlertContext);

    useEffect(() => {
        getAnnouncements();
    }, []);
    
    const getAnnouncements = () => {
        API.get('/api/announcements')
            .then(response => {alert.info(response.data)})
            .catch(error => {
                if (!error.response) {
                    alert.error('Could not fetch announcements. Please try again later');
                    return;
                }
                const data = error.response.data;
                switch (error.status) {
                    case 400:
                        alert.warning(Array.isArray(data) ? data : data.value);
                        break;
                    default:
                        alert.error(data.value === undefined ? data : data.value);
                }
            });
    }

    return (
        <div>
            <Alert />
            <Tabs defaultActiveKey="batch" className="mb-3">
                <Tab eventKey="individual" title="Tracking">
                    <Tracking />
                </Tab>
                <Tab eventKey="batch" title="Batch">
                    <BatchTracking />
                </Tab>
                <Tab eventKey="raw" title="Raw">
                    <RawTracking />
                </Tab>
            </Tabs>
        </div>
    );
}