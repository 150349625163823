import { useContext } from "react";
import { AlertContext } from "../providers/AlertProvider";
import { Alert as BootstrapAlert } from 'react-bootstrap';

export default function Alert() {
    const alert = useContext(AlertContext);

    return (
        <div>
            {alert.text.length ? (
                <BootstrapAlert variant={alert.type} onClose={() => alert.clear()} dismissible>
                    {alert.text.map((value, i) => (<div key={i}>{value}</div>))}
                </BootstrapAlert>
            ) : null}
        </div>
    );
}