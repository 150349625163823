import './Header.css'
import { Container, Navbar } from 'react-bootstrap';
import React from 'react';

export default function Header() {

    return (
        <Navbar bg='dark' data-bs-theme='dark' sticky='top'>
            <Container fluid>
                <Navbar.Text><a href='/'>tracking.ljunggren.io</a></Navbar.Text>
            </Container>
        </Navbar>
    );
}