import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from './components/dashboard/Dashboard';
import AlertProvider from './components/providers/AlertProvider';
import Layout from './components/pages/layout/Layout';

function App() {

  return (
    <AlertProvider>
      <BrowserRouter>
        <Routes>
          <Route path='' element={<Layout /> }>
            <Route path='/' element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AlertProvider>
  );
}

export default App;
