import React, { useContext, useState } from 'react';
import * as API from '../../../api/API';
import { Button, FloatingLabel, Form, Stack } from 'react-bootstrap';
import ResponseTable from '../../response/ResponseTable';
import Submit from '../../buttons/Submit';
import { AlertContext } from '../../providers/AlertProvider';

export default function Tracking() {
    const [carrier, setCarrier] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [response, setResponse] = useState(null);
    const [processing, setProcessing] = useState(false);

    const alert = useContext(AlertContext);

    const handleCarrierChange = event => {
        setCarrier(event.target.value);
    }

    const handleTrackingNumberChange = (event) => {
        setTrackingNumber(event.target.value);
    }

    const handleReset = () => {
        setCarrier('');
        setTrackingNumber('');
        setResponse(null);
        alert.clear();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        alert.clear();
        setResponse(null);
        setProcessing(true);
        const data = JSON.stringify({
            trackingNumber: trackingNumber,
            carrier: carrier,
        });
        API.postJSON('/api/tracking', data)
            .then(response => setResponse(response.data))
            .catch(error => {
                if (!error.response) {
                    alert.error('Could not process request. Please try again later');
                    return;
                }
                const data = error.response.data;
                switch (error.status) {
                    case 400:
                        alert.warning(Array.isArray(data) ? data : data.value);
                        break;
                    default:
                        alert.error(data.value === undefined ? data : data.value);
                }})
            .finally(() => setProcessing(false));
    }
    
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <FloatingLabel 
                    controlId="floatingSelect" 
                    label="Carrier" 
                    className="mb-3"
                >
                    <Form.Select 
                        value={carrier}
                        onChange={handleCarrierChange}
                        required
                    >
                        <option value='' hidden>Select Carrier</option>
                        <option value='FEDEX'>Fedex</option>
                        <option value='UPS'>UPS</option>
                        <option value='DHL'>DHL</option>
                        <option value='USPS'>US Postal Service</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Tracking Number"
                    className="mb-3"
                >
                    <Form.Control 
                        type="text" 
                        value={trackingNumber}
                        onChange={handleTrackingNumberChange}
                        placeholder="Tracking Number" 
                        required 
                    />
                </FloatingLabel>
                <Stack direction="horizontal" gap={2} className="mb-3">
                    <Submit processing={processing}/>
                    <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                </Stack>
                <ResponseTable data={response} />
            </Form>
        </div>
    );
}