import { createContext, useState } from "react";

const AlertContext = createContext(null);
AlertContext.displayName = 'AlertContext';

const AlertProvider = ({ children }) => {
    const [type, setType] = useState(null);
    const [text, setText] = useState([]);

    const setTextArray = (text) => {
        setText(Array.isArray(text) ? text : [text]);
    }

    return (
        <AlertContext.Provider
            value = {{
                type: type,
                text: text,
                success: (text) => {
                    setType('success');
                    setTextArray(text);
                },
                info: (text) => {
                    setType('primary');
                    setTextArray(text);
                },
                warning: (text) => {
                    setType('warning');
                    setTextArray(text);
                },
                error: (text) => {
                    setType('danger');
                    setTextArray(text);
                },
                clear: () => {
                    setText([]);
                    setType(null);
                }
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
export { AlertContext };
