import './BatchTracking.css';
import React, { useContext, useState } from 'react';
import * as API from '../../../api/API';
import FileParser from '../../fileParser/FileParser';
import { Button, FloatingLabel, Form, Stack } from 'react-bootstrap';
import Submit from '../../buttons/Submit';
import Info from '../../info/Info';
import { AlertContext } from '../../providers/AlertProvider';

export default function BatchTracking() {
    const [carrier, setCarrier] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [trackingNumbers, setTrackingNumbers] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [fileParserKey, setFileParserKey] = useState(1);

    const alert = useContext(AlertContext);

    const handleCarrierChange = event => {
        setCarrier(event.target.value);
    }

    const handleTitleChange = event => {
        setTitle(event.target.value);
    }

    const handleEmailChange = event => {
        setEmail(event.target.value);
    }

    const handleTrackingNumberChange = event => {
        let trackingNumbers = event.target.value.split('\n').map(trackingNumber => trackingNumber?.trim());
        setTrackingNumbers(trackingNumbers);
    }

    const handleReset = () => {
        resetData();
        alert.clear();
    }

    const resetData = () => {
        setCarrier('');
        setTitle('');
        setTrackingNumbers([]);
        setFileParserKey(fileParserKey * -1);
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        alert.clear();
        setProcessing(true);
        const data = JSON.stringify({
            trackingNumbers: trackingNumbers,
            title: title,
            email: email,
            carrier: carrier,
        });
        API.postJSON('/api/tracking/batch', data)
            .then(() => {
                alert.success('Request submitted. You will receive an email (' + email + ') once processing is complete');
                resetData();
            })
            .catch(error => {
                if (!error.response) {
                    alert.error('Could not process batch. Please try again later');
                    return;
                }
                const data = error.response.data;
                switch (error.status) {
                    case 400:
                        alert.warning(Array.isArray(data) ? data : data.value);
                        break;
                    default:
                        alert.error(data.value === undefined ? data : data.value);
                }})
            .finally(() => setProcessing(false));
    }
    
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <FloatingLabel 
                    controlId="floatingSelect" 
                    label="Carrier" 
                    className="mb-3"
                >
                    <Form.Select 
                        value={carrier}
                        onChange={handleCarrierChange}
                        required
                    >
                        <option value='' hidden>Select Carrier</option>
                        <option value='FEDEX'>Fedex</option>
                        <option value='UPS'>UPS</option>
                        <option value='DHL'>DHL</option>
                        <option value='USPS'>US Postal Service</option>
                    </Form.Select>
                    {carrier === 'USPS' ? (
                        <Info 
                            label={'Special considerations for US Postal Service'}
                            tip={'The Postal Service limits tracking to 10 per minute. Please allow for additional processing time'}
                        />
                    ) : null}
                    {carrier === 'DHL' ? (
                        <Info 
                            label={'Special considerations for DHL'}
                            tip={'DHL has a strict daily limit of allowed tracking'}
                        />
                    ) : null}
                </FloatingLabel>
                <div className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Batch Title"
                    >
                        <Form.Control 
                            type="text" 
                            onChange={handleTitleChange}
                            placeholder="Batch Title" 
                            value={title}
                        />
                    <Info 
                        label={'What is this?'}
                        tip={'(Optional) Add a title to your batch that will be reflected in the results email'}
                    />
                    </FloatingLabel>
                </div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                >
                    <Form.Control 
                        type="email" 
                        onChange={handleEmailChange}
                        placeholder="name@example.com" 
                        value={email}
                        required 
                        className="mb-3"
                    />
                </FloatingLabel>
                <FileParser 
                    key={fileParserKey}
                    setTrackingNumbers={setTrackingNumbers} 
                />
                <FloatingLabel
                    controlId="floatingTextarea"
                    label="Tracking Numbers"
                >
                    <Form.Control 
                        as="textarea" 
                        placeholder='Tracking Numbers'
                        value={trackingNumbers.join('\n')} 
                        onChange={handleTrackingNumberChange}
                        style={{ height: '250px' }}
                        className="mb-3"
                        required
                        />
                </FloatingLabel>
                <Stack direction="horizontal" gap={2} className="mb-3">
                    <Submit processing={processing}/>
                    <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                </Stack>
            </Form>
        </div>
    )
}